import React from "react";
import Seo from "../components/seo";
import Header from "../components/header/Header";
import CommonDoc from "../components/CommonDoc"
import ImgImani from "../images/links-imani-03.png"
import CommonDocProfile from "../components/CommonDocProfile"
import ImgTeta1 from "../images/links-tera-01.png"
import ImgTeta2 from "../images/links-tera-02.png"
import ImgIDream from "../images/links-dream-01.png"

// markup
const AboutPage = (props) => {
  return (
    <main>
      <Seo lang={`en`} title={`Links`} />

      <div>
        <Header lang={`en`} />
      </div>

      <CommonDoc>
        <h2>Links</h2>
        <p>
          The people, works, and pages which contributed their multi-layered perspectives on the artist-in-residence program “Liminal Space” and this website.
        </p>
        <hr/>
        <section>
          <h3>Blair Imani | Why Do We Have Women’s History Month?<br/>
           </h3>
          <p>(From Imani's <a href={`https://www.youtube.com/@blairimani`} target={`_blank`}>YouTube</a> "Get Smarter with Blair Imani")</p>
          <p>
            While the world was in turmoil due to the pandemic and she herself was caught up in the unrest, Teresa Feldmann repeatedly watched the short videos introduced on Imani Blair's Instagram and YouTube channel and listened to the various "voices" shared on social media.
            Imani's channel covers a wide range of important topics in today's society in a lively and light-hearted manner.
          </p>
          <figure>
            <img src={ImgImani} alt="" />
          </figure>
          <CommonDocProfile>
            <h4>Blair Imani (she/her)</h4>
            <p>
              Blair Imani (she/her) is the LA Times Bestselling author of Read This to Get Smarter. She is an award-winning educator, historian and influencer. She is also the author of Making Our Way Home, and Modern HERstory. Her scholarship spans intersectionality, gender studies, race and racism, sociology, and United States history. She has presented at universities including Oxford, Stanford, Harvard, Duke, and many more. She serves on the Board of Directors for the Tegan and Sara Foundation, and on the Board of Trustees for Muslims for Progressive Values. The New York Times praises Blair Imani’s unique ability to create “progressive lessons with vibrant visuals and a perky, quirky delivery.” She was named to the Root 100 in 2019 and OUT 100 in 2018. She lives in Los Angeles, California.
            </p>
            <p>
              Her work centers women and girls, global Black communities, and the LGBTQ community. As an educator and influencer, semi-retired organizer, and public speaker, Blair Imani is dedicated to making the world a better place and amplifying the voices and work of those fighting the good fight. Learn more about Blair Imani by visiting blairimani.com and joining her on social media @blairimani.
            </p>
            <p>
              <a href={`https://blairimani.com/`} target={`_blank`}>
                Website
              </a>
              ,&nbsp;
              <a
                href={`https://www.instagram.com/blairimani/`}
                target={`_blank`}
              >
                Instagram
              </a>
            </p>
          </CommonDocProfile>
        </section>

        <hr/>

        <section>
          <h3>Kento Terada</h3>
          <p>
            When we asked for rewrites through crowdsourcing for this project, we included questions about "family images" to explore the vacillation between the demands of society and the individual thoughts. Although Terada was not involved in the program as a participating artist, the narratives inherent in his photographic works, in which he himself appears, provide the viewer with a margin for "family images." Terada participated in the group exhibition "Realizing Humanness" (April-May 2021, Alt_Medium, Tokyo), and also appeared in the related talk "<a href={`https://www.youtube.com/watch?v=6D2vYOUi_Ew`} target={`_blank`}>The
            Family in the Future: From the Perspective of Family Law</a>"(Japanese only) with researcher Kazuki Matsuda and another participating artist Sanghyun Park.
          </p>
          <figure>
            <img src={ImgTeta1} alt="" />
            <figcaption>
              <i>Cakes, wife, daughter</i>, 2020, Archival Pigment Print, 508×610mm, ©Kento Terada
            </figcaption>
          </figure>
          <figure>
            <img src={ImgTeta2} alt="" />
            <figcaption>
              <i>Park, daughter</i>, 2021, Archival Pigment Print, 508×610mm, ©Kento Terada
            </figcaption>
          </figure>
          <CommonDocProfile>
            <h4>Kento Terada</h4>
            <p>
              Born in Okinawa Prefecture in 1991. Graduated from Okinawa Prefectural University of Arts, Department of Arts in 2017. Completed the Department of Inter media arts(IMA), Tokyo University of the Arts in 2019. In 2021, enrolled in the doctoral program at the Graduate School of Urban Innovation, Yokohama National University.
              <br />
              Kento Terada is interested in biopolitics, in which people's behaviors and thoughts are determined by the norms of "sex" and "born" created by society. As a practice of "The personal is political” created by radical feminism, he mainly focuses on performance and photography.
            </p>
          </CommonDocProfile>
        </section>
        <hr/>

        <section>
          <h3>i dream of covid</h3>
          <figure>
            <img src={ImgIDream} alt="" />
          </figure>
          <p><a href={`https://www.idreamofcovid.com`} target={`_blank`}>www.idreamofcovid.com</a></p>
        </section>
      </CommonDoc>

    </main>
  );
};

export default AboutPage;
